import * as React from "react";
import { graphql } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import { Helmet } from "react-helmet";
import styled from "styled-components";

import Layout from "../components/layout";
import SponsorsSection from "../components/SponsorsSection";

const TitleText = styled.h1`
    @media (max-width: 1000px) {
        margin-left: 1rem;
        margin-right: 1rem;
    }
`;

const Paragraph = styled.p`
    @media (max-width: 1000px) {
        margin-left: 1rem;
        margin-right: 1rem;
    }
`;

// markup
const IndexPage = ({ data }) => {
    const sponsors = data.sponsorInfo.sponsors;

    return (
        <Layout>
            <Helmet>
                <title>Home</title>
            </Helmet>
            <TitleText>Tour de Haft</TitleText>
            <StaticImage
                src="../../static/images/homepage-image.jpg"
                alt="A row of cyclist lined up single file in green jerseys"
            />
            <Paragraph>
                The Tour de Haft is an annual bike ride from Lancaster County to
                The Haft to raise money for the Haft.
            </Paragraph>
            <SponsorsSection sponsors={sponsors} />
        </Layout>
    );
};

export default IndexPage;

export const query = graphql`
    query {
        sponsorInfo {
            sponsors {
                level
                link
                name
                imageName {
                    childImageSharp {
                        gatsbyImageData
                    }
                }
            }
        }
    }
`;
